import React from "react";
import "./Button.css";

const Buttons = (props) => {
  return (
    <>
      <li
        className="custom-button-li  "
        // xstyle={{ backgroundColor: props.bgcolor }}
      >
        {props.title}
        <span className="custom-button-span"></span>
        <span className="custom-button-span"></span>
        <span className="custom-button-span"></span>
        <span className="custom-button-span"></span>
      </li>
    </>
  );
};

export default Buttons;
