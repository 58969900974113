import React from "react";
import "./Loader.css";

function Loader() {
  return (
    <div>
      <div class="center" id="toggle">
        <div class="header">Loading...</div>
        <div className="fw-bold brand-name-loader">Atomos Technologies </div>
        <p class="virus">The Future of Software Development</p>
        <div class="progressbar">
          <div></div>
        </div>
        {/* </br> */}
        {/* <button>Enter</button> */}
      </div>
    </div>
  );
}

export default Loader;
