import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <>
      {}
      <div className="container mt-5">
        <h1 className="main-heading">PRIVACY POLICY</h1>
        <div className="content mt-4">
          <h3 className="mb-3 secondary-heading">1. Introduction</h3>
          <p className="descrip ">
            Welcome to www.atomostechnologies.com (the "Website"), owned and
            operated by Atomos Technologies OPC Private Limited ("Atomos
            Technologies," "we," "us," or "our"). This Privacy Policy outlines
            our practices regarding the collection, use, and disclosure of
            personal information when you use our Website and related services.
            <br />
            By accessing or using our Website, you agree to the terms of this
            Privacy Policy. If you do not agree with the terms of this Privacy
            Policy, please do not use our Website.
          </p>
          <br />
          <h3 className="mb-3 secondary-heading">2. Information We Collect</h3>
          <br />
          <h3 className="mb-3 secondary-heading">2.1 Personal Information</h3>
          <p className="descrip ">
            When you use our Website, we may collect personal information that
            you provide to us, including but not limited to:
            <br />- Name
            <br />- Email address
            <br />- Contact information
            <br />- Billing and payment information
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            {" "}
            2.2 Automatically Collected Information
          </h3>
          <p className="descrip ">
            We may also automatically collect information about your usage of
            the Website, including:
            <br />- Browser type
            <br />- Operating system
            <br />- Date and time of access
            <br />- Website pages visited
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading"> 3. Use of Information</h3>
          <p className="descrip ">
            We use the information we collect for the following purposes:
            {/* <br /> */}
            <br />- To provide and maintain the Website and our services
            <br />- To process and complete transactions
            <br />- To communicate with you, respond to inquiries, and provide
            customer support
            <br />- To send newsletters, marketing communications, and
            promotional materials (if you have opted in)
            <br />- To improve our Website and services
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            {" "}
            4. Disclosure of Information
          </h3>
          <p className="descrip ">
            We may disclose your personal information in the following
            circumstances:
            <br />
            - To third-party service providers who assist us in operating the
            Website and providing services
            <br />
            - To comply with legal obligations, such as responding to court
            orders or government requests
            <br />
            - To protect our rights, privacy, safety, or property, and the
            public
            <br />- In connection with a business transaction, such as the sale
            or transfer of Atomos Technologies
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">5. Security</h3>
          <p className="descrip ">
            We take reasonable measures to protect the security of your personal
            information. However, no method of transmission over the internet or
            electronic storage is completely secure. Therefore, we cannot
            guarantee absolute security.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            6. Cookies and Tracking Technologies
          </h3>
          <p className="descrip ">
            We use cookies and similar tracking technologies to enhance your
            experience on the Website. You can set your browser to refuse
            cookies or alert you when cookies are being sent.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">7. Your Choices</h3>
          <p className="descrip ">
            You can control the information you provide and the communications
            you receive. You may update your preferences or opt-out of certain
            communications by contacting us at care@atomostechnologies.com.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            8. Changes to this Privacy Policy
          </h3>
          <p className="descrip ">
            We may update this Privacy Policy from time to time. The latest
            version will be posted on the Website with the effective date. Your
            continued use of the Website after any changes constitutes
            acceptance of the updated Privacy Policy.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">9. Contact Us</h3>
          <p className="descrip ">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
            <br />
            -Atomos Technologies OPC Private Limited
            <br />
            -36, Cowies Ghat Road, Shibpur, Howrah, West Bengal, India - 711102
            <br />
            -Email: care@atomostechnologies.com
          </p>
          <br />
          <br />
          {/* <br /> */}
        </div>
      </div>
    </>
  );
}

export default Privacy;
