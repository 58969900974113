import React from "react";
import "./Card.css";
import Data from "./ServicesData";
// import Slide from "react-reveal/Slide";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Cards() {
  const animation = useAnimation();
  const [inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
        transition: { duration: 1 },
      });
    }
  }, [inView, animation]);

  const scrollElements = document.querySelectorAll(".js-scroll");

  const elementInView = (el, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
      elementTop <=
      (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
  };

  const displayScrollElement = (element) => {
    element.classList.add("scrolled");
  };

  const hideScrollElement = (element) => {
    element.classList.remove("scrolled");
  };

  const handleScrollAnimation = () => {
    scrollElements.forEach((el) => {
      if (elementInView(el, 1)) {
        displayScrollElement(el);
      } else {
        hideScrollElement(el);
      }
    });
  };

  window.addEventListener("scroll", () => {
    handleScrollAnimation();
  });

  return (
    <>
      {Data.map((element) => {
        return (
          <div class="my-services-row snipcss-DaBxv">
            {/* <Slide right> */}
            <div
              class="my-services-row__left style-3z3dt scroll-container"
              id="style-3z3dt"
            >
              <div class="my-services-row__left--card scroll-element js-scroll slide-right reveal-card-mobile snipcss0-0-0-1">
                <div class="my-services-row__icon">
                  <img
                    src={require(`./../Assets/Images/${element.image}`)}
                    alt="notices"
                    className="EventCardImg"
                    draggable={false}
                    height="40"
                    width="40"
                  />
                </div>
                <div class="my-services-row__text snipcss0-1-1-4">
                  <div class="my-services-row__text--top snipcss0-2-4-5">
                    {element.cardTitle}
                  </div>
                  <div class="my-services-row__text--bottom snipcss0-2-4-6">
                    {element.desc}
                  </div>
                </div>
                <div
                  class="card-extension snipcss0-1-1-7 style-bU2Vt"
                  id="style-bU2Vt"
                ></div>
              </div>
            </div>
            {/* </Slide> */}

            {/* <Slide left> */}
            <div
              class="my-services-row__right style-D6rWt scroll-container"
              id="style-D6rWt"
            >
              <div class="my-services-row__right--card scroll-element js-scroll slide-left snipcss0-0-0-1">
                <div class="my-services-row__text--right snipcss0-1-1-2">
                  {element.rightTitle}
                </div>

                <h1 className="snipcss0-3-4-5">
                  <i>{element.title}</i>
                </h1>
              </div>
            </div>
            {/* </Slide> */}
          </div>
        );
      })}
      {/* </motion.div> */}
    </>
  );
}

export default Cards;
