const data = [
  {
    image: "2.png",
    rightTitle: "",
    cardTitle: "Software Development",
    title: "Custom Software Development",
    desc: "Unlock the full potential of your business with our tailor-made software solutions. Our expert team of software engineers, designers, and project managers will work closely with you to understand your unique requirements and develop high-quality, scalable, and efficient software that exceeds your expectations.",
  },
  {
    image: "1.png",
    rightTitle: "",
    cardTitle: "Website Development",
    title: "Website Design and Development",
    desc: "Create a powerful online presence with our stunning, user-friendly, and responsive websites. From concept to launch, we ensure your website reflects your brand identity, engages your target audience, and drives conversions.",
  },
  {
    image: "3.png",
    rightTitle: "",
    cardTitle: "Mobile App Development",
    title: "Mobile App Development",
    desc: "Stay ahead of the curve with innovative native and cross-platform mobile apps that deliver exceptional user experiences. Our talented app developers are adept at crafting seamless and feature-rich apps for iOS, Android, and other platforms.",
  },
  {
    image: "4.png",
    rightTitle: "",
    cardTitle: "Blockchain Development",
    title: "Blockchain Application Development",
    desc: "Leverage the power of blockchain technology for enhanced security, transparency, and efficiency. Our blockchain experts will help you design and develop decentralized applications (dApps) and smart contracts that revolutionize the way you conduct business.",
  },
  {
    image: "6.png",
    rightTitle: "",
    cardTitle: "Cybersecurity Solutions",
    title: "Cybersecurity Solutions",
    desc: "Protect your digital assets and customer data with our robust cybersecurity measures. We provide end-to-end security solutions, including threat assessment, vulnerability scanning, penetration testing, and secure infrastructure implementation.",
  },
  {
    image: "5.png",
    rightTitle: "",
    cardTitle: "Game Development",
    title: "Game Development for All Platforms",
    desc: "Immerse your users in captivating gaming experiences across all platforms, including VR and AR. Our skilled game developers and designers are dedicated to creating visually stunning, engaging, and interactive games that keep your audience entertained and coming back for more.",
  },
];

export default data;
