const timelineElements = [
  {
    id: 1,
    title: "1. Discovery and Analysis",
    icon: "1",
    description:
      "We begin by understanding your goals, target audience, and unique requirements. This helps us to create a comprehensive project plan and a customized solution tailored to your needs.",
  },
  {
    id: 2,
    title: "2. Design and Prototyping",
    icon: "2",
    description:
      "Our creative team designs a visually appealing, user-friendly, and intuitive interface, followed by the development of a functional prototype to validate the concept and gather feedback.",
  },
  {
    id: 3,
    title: "3. Development and Testing",
    icon: "3",
    description:
      "Our developers then build your custom solution, ensuring it meets the highest quality standards. Rigorous testing is conducted to identify and fix any issues before deployment.",
  },
  {
    id: 4,
    title: "4. Deployment and Support",
    icon: "4",
    description:
      "After a successful launch, we offer ongoing maintenance, support, and updates to ensure your digital product remains up-to-date and secure in the ever-changing digital landscape.",
  },
];

export default timelineElements;
