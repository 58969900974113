import React from "react";

const Shipping = () => {
  return (
    <>
      {}
      <div className="container mt-5">
        <h1 className="main-heading">Shipping & Delivery Policy</h1>
        <div className="content mt-4">
          <p className="descrip ">
            This Shipping & Delivery Policy ("Policy") is applicable to all
            users of the website www.atomostechnologies.com ("Website") owned by
            Atomos Technologies OPC Private Limited ("Atomos Technologies,"
            "we," "us," or "our"), with registered address at 36, Cowies Ghat
            Road, Shibpur, Howrah, West Bengal, India - 711102. This Policy
            outlines the terms and conditions governing the shipping and
            delivery of software services and digital products offered by Atomos
            Technologies.
          </p>
          <br />
          <h3 className="mb-3 secondary-heading">1. Scope of Policy:</h3>
          <p className="descrip ">
            This Policy applies to clients who seek software solutions through
            the Website and make purchases of software services or digital
            products developed by Atomos Technologies.
          </p>
          <br />
          <br />
          <h3 className="mb-3 secondary-heading">2. Services and Products:</h3>

          <p className="descrip ">
            Clients can select their required software services or digital
            products on the Website. The purchased services may include software
            development, customization, and related services. Digital products
            may include software applications and other digital goods.
          </p>
          <br />
          <br />
          {/* <br /> */}

          {/* <br /> */}
          <h3 className="mb-3 secondary-heading"> 3. Order Placement:</h3>
          <p className="descrip ">
            Clients may place orders for services or products on the Website by
            providing their personal details through the secure order placement
            process. Orders may be placed online, and payment can be made
            securely on the Website or in-person.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading"> 4. Delivery Methods:</h3>
          <p className="descrip ">
            -Online Delivery: Software services will be delivered online, and
            clients may access them through email or special downloadable links
            provided by Atomos Technologies.
            <br />
            -Offline Delivery: Software products may be delivered offline via
            physical drives provided by the client at an additional cost.
            Offline delivery arrangements will be made in consultation with the
            client.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">5. Delivery Timeframes:</h3>
          <p className="descrip ">
            -The delivery timeframe for online services will be communicated to
            the client upon confirmation of the order.
            <br />
            -For offline delivery, the timeframe will depend on the location of
            the client and the shipping method chosen.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">6. Installation Charges:</h3>
          <p className="descrip ">
            - Installation of purchased software services may or may not incur
            additional charges. The terms regarding installation charges will be
            specified in the contract between Atomos Technologies and the user.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">7. User Responsibilities:</h3>
          <p className="descrip ">
            -Clients are responsible for providing accurate and complete
            delivery information.
            <br />
            -In the case of offline delivery, clients must ensure the
            availability of a suitable delivery address and contact person.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            8. Communication and Support:
          </h3>
          <p className="descrip ">
            -Clients can reach out for support regarding shipping and delivery
            at care@atomostechnologies.com.
            <br />
            -Atomos Technologies will communicate any delays or issues related
            to shipping and delivery promptly.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">9. Delivery Confirmation:</h3>
          <p className="descrip ">
            -Clients will receive confirmation of delivery for online services
            via email or other electronic means.
            <br />
            -For offline delivery, a confirmation receipt will be provided upon
            successful delivery.
          </p>
          <br />
          <br />
          <h3 className="mb-3 secondary-heading">10. Changes to Policy:</h3>
          <p className="descrip ">
            Atomos Technologies reserves the right to modify this Shipping &
            Delivery Policy at any time. Clients will be notified of any changes
            through the Website.
            <br />
            By using the services and purchasing digital products on the
            Website, clients acknowledge and agree to comply with this Shipping
            & Delivery Policy. This Policy is an integral part of the Terms of
            Service of Atomos Technologies and should be read in conjunction
            with other applicable policies.
          </p>
          <br />
          <br />
          {/* <br /> */}
        </div>
      </div>
    </>
  );
};

export default Shipping;
