import React, { useState } from "react";
import About from "./../About/About";
import Projects from "./../Projects/Projects";
import Services from "./../Services/Services";
import Proccess from "./../Proccess/Proccess";
import Contact from "./../Contact/Contact";
import "./HeroSection.css";
import cardicon from "./../Assets/Images/cardicon.png";
import servicearray from "./../Assets/Images/serviceArrow.png";
import workicon from "./../Assets/Images/workicon.png";
import abouticon from "./../Assets/Images/abouticon.png";
import contacticon from "./../Assets/Images/contacticon.png";
import OwnCard from "../Own_Cards/OwnCard";

function Hero() {
  const [square, setSquare] = useState(false);
  const [circle, setCircle] = useState(false);
  const [triangle, setTriangle] = useState(false);
  return (
    <>
      {/* <Zoom left> */}
      <div class="nav-container">
        <div class="nav-left">
          <a href="#ServicesSection">
            <div class="services nav-item">
              <div class="services nav-item">
                <div class="cards-container">
                  <div class="shapes-container">
                    <div class="shapes">
                      <div
                        // id="square"
                        // className={
                        //   square
                        //     ? "hover-square hover-square-triangle hover-square-circle"
                        //     : "square"
                        // }
                        className="square"
                        style={{
                          height: square
                            ? "150px"
                            : triangle
                            ? "50px"
                            : circle
                            ? "50px"
                            : "",
                          width: square
                            ? "150px"
                            : triangle
                            ? "50px"
                            : circle
                            ? "50px"
                            : "",
                          backgroundColor: square ? "#fff" : "",
                        }}
                      ></div>
                      <div
                        // id="circle"
                        className="circle"
                        style={{
                          height: square
                            ? "50px"
                            : triangle
                            ? "50px"
                            : circle
                            ? "150px"
                            : "",
                          width: square
                            ? "50px"
                            : triangle
                            ? "50px"
                            : circle
                            ? "150px"
                            : "",
                          backgroundColor: circle ? "#ffc0cb" : "",
                        }}
                      ></div>
                      <div
                        // id="triangle"
                        className="triangle"
                        style={{
                          borderLeft: square
                            ? "34px solid transparent"
                            : circle
                            ? "34px solid transparent"
                            : triangle
                            ? "70px solid transparent"
                            : "",
                          borderRight: square
                            ? "34px solid transparent"
                            : circle
                            ? "34px solid transparent"
                            : triangle
                            ? "70px solid transparent"
                            : "",
                          borderBottom: square
                            ? "43px solid #A5A1FF"
                            : circle
                            ? "43px solid #A5A1FF"
                            : triangle
                            ? "130px solid #FFF480"
                            : "",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div class="cards">
                    <div class="cards-title fw-bold">Atomos Technologies</div>
                    <div class="cards-logo">
                      <img src={cardicon} alt="" height="60" width="60" />
                    </div>
                    <div class="service-cards-icon">
                      <img src={servicearray} alt="" height="30" width="30" />
                    </div>

                    <div class="preview-text fw-bold">See our services</div>
                    {/* <div class="text-wrapper">
                      We are passionate about Web Design. We are passionate
                      about Web Design. We are passionate about Web Design. We
                      are passionate about Web Design. We are passionate about
                      Web Design. We are passionate about Web Design. We are
                      passionate about Web Design. We are passionate about Web
                      Design. We are passionate about Web Design. We are
                      passionate about Web Design. We are passionate about Web
                      Design. We are passionate about Web Design. We are
                      passionate about Web Design. We are passionate about Web
                      Design.
                    </div> */}
                    <div
                      class="services-marquee"
                      style={{
                        display: square
                          ? "none"
                          : circle
                          ? "none"
                          : triangle
                          ? "none"
                          : "",
                      }}
                    >
                      <div class="services-track">
                        <div class="content">
                          &nbsp;We are passionate about Software & Information
                          Technology. We are passionate about Software &
                          Information Technology{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="nav-right">
          <div class="nav-top">
            <a
              href="#work"
              onMouseOver={() => {
                setSquare(true);
                // alert(hoverSquare);
              }}
              onMouseOut={() => {
                setSquare(false);
              }}
            >
              <div class="work nav-item">
                <div class="cards-container ">
                  <div class="cards">
                    <div class="cards-icon">
                      <img src={workicon} alt="" height="30" width="30" />
                    </div>

                    <div
                      class="preview-text"
                      style={{
                        fontWeight: "var(--primary-font)",
                        fontSize: "var(--secondary-size)",
                      }}
                    >
                      Work
                    </div>
                    {/* <div
                      class="text-wrapper"
                      style={{ fontSize: "0.5rem !important" }}
                    >
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                      View our showcase. View our showcase. View our showcase.
                    </div> */}

                    <div class="text-wrapper">
                      <div class="text-wrapper-track">
                        <div
                          class="content"
                          style={{ color: "black", fontSize: "4rem" }}
                        >
                          &nbsp;View our showcase. View our showcase.showcase.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="#about"
              onMouseOver={() => setCircle(true)}
              onMouseOut={() => setCircle(false)}
            >
              <div class="about nav-item">
                <div class="cards-container">
                  <div class="cards">
                    <div class="cards-icon">
                      <img src={abouticon} alt="" height="30" width="30" />
                    </div>

                    <div
                      class="preview-text light"
                      style={{
                        fontWeight: "var(--primary-font)",
                        fontSize: "var(--secondary-size)",
                      }}
                    >
                      About us
                    </div>
                    {/* <div
                      class="text-wrapper light"
                      style={{ fontSize: "0.5rem !important" }}
                    >
                      Learn more about us. Learn more about us. Learn more about
                      us. Learn more about us. Learn more about us. Learn more
                      about us. Learn more about us. Learn more about us. Learn
                      more about us. Learn more about us. Learn more about us.
                      Learn more about us. Learn more about us. Learn more about
                      us.
                    </div> */}

                    <div class="text-wrapper">
                      <div class="text-wrapper-track">
                        <div
                          class="content"
                          style={{ color: "#fff", fontSize: "4rem" }}
                        >
                          &nbsp;Learn more about us. Learn more about us. Learn
                          more about us.{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="nav-bottom">
            <a
              href="#contact"
              onMouseOver={() => setTriangle(true)}
              onMouseOut={() => setTriangle(false)}
            >
              <div class="contacts nav-item">
                <div class="contacts nav-item">
                  <div class="cards-container">
                    <div class="cards">
                      <div class="cards-icon">
                        <img src={contacticon} alt="" height="30" width="30" />
                      </div>

                      <div
                        class="preview-text"
                        style={{
                          fontWeight: "var(--primary-font)",
                          fontSize: "var(--secondary-size)",
                        }}
                      >
                        Contact us
                      </div>
                      {/* <div
                        class="text-wrapper"
                        style={{ fontSize: "0.5rem !important" }}
                      >
                        Get in touch. Let's talk businesss. Get in touch. Let's
                        talk businesss. Get in touch. Let's talk businesss. Get
                        in touch. Let's talk businesss. Get in touch. Let's talk
                        businesss. Get in touch. Let's talk businesss. Get in
                        touch. Let's talk businesss. Get in touch. Let's talk
                        businesss. Get in touch. Let's talk businesss. Get in
                        tGet in touch. Let's talk businesss. Get in touch. Let's
                        talk businesss. Get in touch. Let's talk businesss.
                        ouch. Let's talk businesss. Get in touch. Let's talk
                        businesss.
                      </div> */}

                      <div class="text-wrapper">
                        <div class="text-wrapper-track">
                          <div
                            class="content"
                            style={{ color: "black", fontSize: "4rem" }}
                          >
                            &nbsp;Get in touch. Let's talk businesss. Get in
                            touch. Let's talk businesss.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* </Zoom> */}
      <Services />
      <Projects />
      <OwnCard />
      <Proccess />
      <About />
      <Contact />
    </>
  );
}

export default Hero;
