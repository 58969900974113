import React from "react";
import cifer from "./../Assets/Images/cifer.png";
import homeServise from "./../Assets/Images/homeService.png";
import funFlix from "./../Assets/Images/funFlix.png";
import fingerPrint from "./../Assets/Images/fingerPrint.png";
import unicorn from "./../Assets/Images/unicor.png";
import arrow from "./../Assets/Images/arrow2.png";
import "./Projects.css";

function Projects() {
  return (
    <section className="ProjectsSection px-4" id="work">
      <div className="row margin-x">
        <div className="col-12">
          <a href="https://cifer.io/" target="blank">
            <div className=" boxes box-1">
              <div className="top-div up">
                <h5 className="text-light fs-4">01.</h5>
                <h5 className="text-light fs-4">Featured</h5>
              </div>
              <div className="logo-div">
                <img
                  className="workLogoDiv"
                  draggable="false"
                  src={cifer}
                  alt="Cifer Logo"
                  // height={200}
                  // width={200}
                />
              </div>
              <div className="lower-div down">
                <h5 className="text-light fs-4 fw-bold">Launch product</h5>
                {/* <h5 className="fs-5">arrow</h5> */}
                <img
                  src={arrow}
                  alt="Cifer Logo"
                  className="logo-img"
                  width="40"
                  height="40"
                />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="row margin-x">
        <div className="col-lg-6 col-md-6 col-6">
          <div className=" boxes box-2">
            <div className="top-div ">
              <h5 className="text-dark fw-bold">02.</h5>
              {/* <h5></h5> */}
            </div>
            <div className="logo-div">
              <img
                draggable="false"
                className="workLogoDiv"
                src={homeServise}
                alt="Home Services Logo"
              />
            </div>
            <div className="lower-div">
              <h5 className="text-dark fw-bold">Project in Progress</h5>
              {/* <h5></h5> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <div className="boxes box-3">
            <div className="top-div">
              <h5 className="text-dark fw-bold">03.</h5>
              {/* <h5></h5> */}
            </div>
            <div className="logo-div">
              <img
                draggable="false"
                className="workLogoDiv"
                src={funFlix}
                alt="Fun FLix Logo"
              />
            </div>
            <div className="lower-div">
              <h5 className="text-dark fw-bold">Project in Progress</h5>
              {/* <h5></h5> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <div className="boxes box-4">
            <div className="top-div">
              <h5 className="text-light fw-bold">04.</h5>
              {/* <h5></h5> */}
            </div>
            <div className="logo-div">
              <img
                className="workLogoDiv"
                draggable="false"
                src={fingerPrint}
                alt="FingerPrint Logo"
                // height={200}
                // width={200}
              />
            </div>
            <div className="lower-div">
              <h5 className="text-light fw-bold">Project in Progress</h5>
              {/* <h5></h5> */}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-6">
          <div className="boxes box-5">
            <div className="top-div">
              <h5 className="text-light fw-bold">05.</h5>
              {/* <h5></h5> */}
            </div>
            <div className="logo-div">
              <img
                className="workLogoDiv"
                draggable="false"
                src={unicorn}
                alt="Unicorn Logo"
                // height={200}
                // width={200}
              />
            </div>
            <div className="lower-div">
              <h5 className="text-light fw-bold">Project in Progress</h5>
              {/* <h5></h5> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
