import React, { useRef } from "react";
// import Heading from "../Heading/Heading";
import emailjs from "emailjs-com";
import contacticon from "./../Assets/Images/contacticon.png";
import Buttons from "../Button/Button";
import "./Contact.css";
import { useState } from "react";
import { Link } from "react-router-dom";

function Contact() {
  const [show, setShow] = useState(false);
  // const [username, setUsername] = useState("");
  const isSuccess = () => {
    if (show) {
      return (
        <>
          <div
            class="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>Thank You,</strong> Your message has been successfully recieved by us. We will get in touch with you within 24 hours.
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        </>
      );
    }
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_lo29tbc",
        "template_u5voodk",
        form.current,
        "KwUtDUW6nxdgQGRln"
      )
      .then(
        (result) => {
          // alert("Massage Sent Successfully");
          setShow(true);
        },
        (error) => {
          alert(error.text);
        }
      );
  };
  return (
    <section className="contactSection" id="contact">
      <div className="contact-heading-div">
        {/* <Heading
          title="GET IN TOUCH!"
          subtitle="Do you have a question, an idea, or a project you need help with? Contact us!"
          color="#D4C951"
          // bgcolor="#1D1C16"
        /> */}

        <div
          className="con-heading"
          style={{ padding: "0rem 1.5rem !important" }}
        >
          <p style={{ color: "#D4C951" }}>GET IN TOUCH!</p>
          <h1 style={{ color: "#D4C951" }}>
            Do you have a question, an idea, or a project you need help with?
            Contact us!
          </h1>
        </div>
        <div className="contact-heading-button-top">
          <Link
            className="btn"
            to="mailto:care@atomostechnologies.com"
            // target="blank"
          >
            <Buttons title="care@atomostechnologies.com" />
          </Link>

          <Link className="btn" to="tel:7890000128">
            <Buttons title="+91 7890000128" />
          </Link>
        </div>
        <div className="contact-heading-button-lower">
          <Link
            className="btn"
            to="https://www.instagram.com/atomos.technologies/?igshid=NTc4MTIwNjQ2YQ%3D%3D"
            target="blank"
            style={{ width: "25%" }}
          >
            <Buttons title={<i class="fa-brands fa-instagram fs-5"></i>} />
          </Link>
          {/* 
          <button className="btn">
            <Buttons title="Facebook" />
          </button> */}
        </div>
      </div>
      <div className="contact">
        <form ref={form} onSubmit={sendEmail}>
          <div className="row contact-row">
            <dic className="col-lg-9 col-md-9 col-12">
              <div className="row pe-0 ps-0">
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <input
                    type="text"
                    placeholder="Name"
                    autocomplete="off"
                    required
                    name="user_name"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <input
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    placeholder="E-mail"
                    autocomplete="off"
                    required
                    name="user_email"
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-12 mt-2">
                  <input
                    type="text"
                    placeholder="Subject"
                    autocomplete="off"
                    // required
                    name="user_phone"
                  />
                </div>
                {/* <div className="col-12 mt-2">
                  <input
                    type="text"
                    placeholder="Messages"
                    className="messege"
                    autocomplete="off"
                    required
                    name="message"
                  />
                </div> */}
                <div className="col-12 mt-2">
                  <textarea
                    type="text"
                    placeholder="Message"
                    className="messege"
                    autocomplete="off"
                    required
                    name="message"
                  ></textarea>
                </div>
              </div>
            </dic>
            <button
              className="col-lg-3 col-md-3 col-12 send"
              type="submit"
              value="Send"
              placeholder="Send"
              on
            >
              Send
              <img src={contacticon} alt="" height="30" width="30" />
            </button>
          </div>
        </form>
      </div>
      {isSuccess()}
    </section>
  );
}

export default Contact;
