import React from "react";
import { HashLink } from "react-router-hash-link";
// import Scrollimg from "./../Assets/Images/ScrollToTop.png";
import "./ScrollToTop.css";
function ScrollToTop({ show }) {
  return (
    <div
      className={show ? "scroll-up-button show" : "scroll-up-button"}
      id="scroll-btn"
    >
      <HashLink to="#">
        <i className="fa-solid fa-angle-up"></i>
        {/* <img
          src={Scrollimg}
          alt="Top Logo"
          //   width={100}
          //   height={60}
          style={{ borderRadius: "1rem" }}
        /> */}
        {/* <i class="fa-light fa-rocket"></i> */}
      </HashLink>
    </div>
  );
}

export default ScrollToTop;
