import React from "react";
import "./Heading.css";
// import Buttons from "../Button/Button";

function Heading(props) {
  return (
    <>
      <div className="heading" style={{ backgroundColor: props.bgcolor }}>
        <p style={{ color: props.color }}>{props.title}</p>
        <h1 style={{ color: props.color }}>{props.subtitle}</h1>
      </div>
    </>
  );
}

export default Heading;
