import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Hero from "./HeroSection/Hero";
import ScrollToTop from "./ScrollToTop/ScrollToTop";
import Footer from "./Footer/Footer";
import Loader from "./Loader/Loader";
import Privacy from "./Others/Privacy";
import Terms from "./Others/Terms";
import "./App.css";
import Contact from "./Contact/Contact";
import About from "./About/About";
import Services from "./Services/Services";
import OwnCard from "./Own_Cards/OwnCard";
import Shipping from "./Others/Shipping";
import Refund from "./Others/Refund";

function App() {
  const [top, setTop] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setTop(true);
      } else {
        setTop(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", window);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path="/" element={<Hero />} />
              <Route path="/t&c" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/shipping" element={<Shipping />} />
              <Route path="/refund" element={<Refund />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/offer" element={<OwnCard />} />
            </Routes>
            <ScrollToTop show={top} />
            <Footer />
          </BrowserRouter>
        </>
      )}
    </>
  );
}

export default App;
