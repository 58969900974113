import React from "react";
// import Heading from "../Heading/Heading";
import "./Proccess.css";
import timelineElements from "./Proccess_data";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Scheduler() {
  const isIcon = { background: "#A5A1FF" };
  const arrowStyle = { color: "#1B1B1F" };
  return (
    <div id="progress" className="ms-1">
      {/* <Heading
        // title="How We Work"
        subtitle="Our Project Workflow."
        color="#A5A1FF"
        bgcolor="#1B1B1F"
      /> */}
      <div className="">
        <h1
          className=" proccess-headinglg d-lg-block d-xl-block d-xxl-block text-center d-sm-none"
          style={{
            color: "#A5A1FF",
            fontSize: "var(--primary-size)",
            fontWeight: "600",
            padding: "4rem 0",
            textAlign: "center!important",
            letterSpacing: "0.1rem",
          }}
        >
          Our Project Workflow
        </h1>
        <h1
          className="text-center proccess-heading d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block"
          style={{
            color: "#A5A1FF",
            fontSize: "var(--primary-size)",
            fontWeight: "600",
            padding: "4rem 0",
            letterSpacing: "0.1rem",
          }}
        >
          Our
          <br /> Project
          <br /> Workflow
        </h1>
      </div>

      {/* <Tilt options={defaultOptions}> */}
      <VerticalTimeline animate={Boolean}>
        {timelineElements.map((element) => {
          return (
            <VerticalTimelineElement
              key={element.key}
              // date={element.date}
              //   time={element.time}
              dateClassName="date"
              iconStyle={isIcon}
              icon={
                <h2
                  style={{
                    color: "#1B1B1F",
                    textAlign: "center",
                    margin: "10px auto",
                  }}
                >
                  {element.icon}
                </h2>
              }
              contentArrowStyle={arrowStyle}
            >
              <h2 className="vertical-timeline-element-title">
                {element.title}
              </h2>

              <p id="description">{element.description}</p>

              {/* <Link href={element.button1Url} className="btn-rule">
                {element.button1Text}
            </Link>*/}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
    // </div>
  );
}

export default Scheduler;
