import React from "react";
import Heading from "../Heading/Heading";
import Cards from "../Cards/Cards";

import "./Services.css";
function Services(props) {
  return (
    <section className="ServicesSection" id="ServicesSection">
      <Heading
        title="WHAT WE DO"
        // subtitle="Welcome to Atomos Technologies, your ultimate partner in the ever-evolving digital world in design and development of custom software, websites, native and cross-platform mobile apps, blockchain applications, cybersecurity measures, and immersive games for all platforms, including Virtual Reality (VR) and Augmented Reality (AR) experiences. With a strong commitment to innovation and customer satisfaction, we strive to deliver exceptional digital products that elevate your business or brand to new heights."
        subtitle={
          "Fuelled by passion, creativity, and an odd obsession with perfection, Atomos Technologies weaves digital dreams into reality one pixel at a time."
        }
        color="#A5A1FF"
        bgcolor="#1B1B1F"
      />
      <Cards />
    </section>
  );
}

export default Services;
