import React from "react";
import logo from "./../Assets/Images/logo.png";
import "./Navbar.css";

function Navbar() {
  return (
    <nav className="Navbar">
      <div>
        <img
          draggable="false"
          src={logo}
          alt="Brand Logo"
          height="100"
          width="100"
        />
      </div>
    </nav>
  );
}

export default Navbar;
