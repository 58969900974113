const cardData = [
  {
    image: "customeSoftware.png",
    alt: "Custom Software",
  },
  {
    image: "website.png",
    alt: "Website and Design",
  },
  {
    image: "mobileApp.png",
    alt: "Mobile App",
  },
  {
    image: "games.png",
    alt: "Games",
  },
  {
    image: "blockchain.png",
    alt: "Blockchain",
  },
  {
    image: "sujay.png",
    alt: "Cybersecurity",
  },
];

export default cardData;
