import React from "react";
import VanillaTilt from "vanilla-tilt";
import CardData from "./OwnCardData";
import { useEffect } from "react";
import "./OwnCard.css";
import Buttons from "../Button/Button";

const OwnCard = () => {
  useEffect(() => {
    VanillaTilt.init(document.querySelectorAll(".card"), {
      max: 5,
      speed: 10,
      glare: true,
      "max-glare": 0.5,
    });
  });
  return (
    <>
      <div className="">
        <h1
          className=" proccess-headinglg d-lg-block d-xl-block d-xxl-block text-center d-sm-none"
          style={{
            color: "#FCD4FE",
            fontSize: "var(--primary-size)",
            fontWeight: "600",
            padding: "4rem 0",
            // paddingButtom: "4rem",
            letterSpacing: "0.1rem",
          }}
        >
          Our Next-Gen Service Catalogue
        </h1>
        <h1
          className="text-center proccess-heading d-md-none d-lg-none d-xl-none d-xxl-none d-sm-block"
          style={{
            color: "#FCD4FE",
            fontSize: "var(--primary-size)",
            fontWeight: "600",
            padding: "4rem 0",
            // paddingButtom: "4rem",
            letterSpacing: "0.1rem",
          }}
        >
          Our Next-Gen
          <br /> Service Catalogue
        </h1>
      </div>
      <div className="container">
        <div className="row ms-2">
          {CardData.map((e) => {
            return (
              <>
                <divs className="col-lg-4 col-md-6 col-12 width-card-offer">
                  <div data-tilt data-tilt-glare data-tilt-max-glare="0.8">
                    <div className="card mt-4 mb-3 mb-2">
                      <img
                        draggable="false"
                        src={require(`./../Assets/Images/${e.image}`)}
                        alt={e.alt}
                      />
                    </div>
                  </div>
                  {/* <div style={{ width: "% !important" }}> */}
                  <a
                    className="offer-card-button fw-bold"
                    href="#contact"
                    // style={{ width: "15% !important" }}
                  >
                    <Buttons
                      // className="offer-card-button"
                      title="Learn More"
                    />
                  </a>
                  {/* </div> */}
                </divs>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OwnCard;
