import React from "react";
import "./Privacy.css";
import Slide from "react-reveal/Zoom";

function Terms() {
  return (
    <>
      {}
      <Slide left>
        <div className="container mt-5">
          <h1 className="main-heading">TERMS AND CONDITIONS</h1>
          <div className="content mt-4">
            <p className="descrip ">
              This document (hereinafter referred to as the "Agreement") is a
              legally binding contract between you (hereinafter referred to as
              the "User" or "Client") and Atomos Technologies OPC Private
              Limited, a company registered in India under the Companies Act,
              2013, with its registered office at 36, Cowies Ghat Road, Shibpur,
              Howrah, West Bengal, India - 711102 (hereinafter referred to as
              "Atomos Technologies," "we," "us," or "our"). By accessing or
              using the website www.atomostechnologies.com (hereinafter referred
              to as the "Website"), including any subdomains, you agree to be
              bound by the terms and conditions outlined in this Agreement. If
              you do not agree with these terms, please refrain from using our
              Website and services.
              <br />
              <br />
              {/* <br /> */}
            </p>
            <h3 className="mb-3 secondary-heading">1. Acceptance of Terms</h3>
            <p className="descrip ">
              By accessing and using our Website, you acknowledge that you have
              read, understood, and agree to be bound by these Terms &
              Conditions, as well as our Privacy Policy. Atomos Technologies
              reserves the right to modify, alter, or update these Terms &
              Conditions at any time without prior notice. It is the User's
              responsibility to review the terms periodically for changes.
              Continued use of the Website after the modifications signify
              acceptance of the updated terms.
            </p>
            <br />
            <br />
            {/* <br /> */}
            <h3 className="mb-3 secondary-heading">2. Services Offered</h3>
            <p className="descrip ">
              Atomos Technologies provides software solutions through its
              Website. Clients can select services, provide personal details for
              communication, and make payments for the selected services or
              digital products. The nature, scope, and pricing of services are
              detailed on the Website and are subject to change at Atomos
              Technologies' discretion.
            </p>
            <br />
            <br />
            {/* <br /> */}
            <h3 className="mb-3 secondary-heading">3. User Registration</h3>
            <p className="descrip ">
              To access certain features of the Website, Users may be required
              to register an account. Users agree to provide accurate, current,
              and complete information during the registration process. Atomos
              Technologies reserves the right to suspend or terminate accounts
              with incomplete or inaccurate information.
            </p>
            <br />
            <br />
            {/* <br /> */}
            <h3 className="mb-3 secondary-heading">4. Personal Information</h3>
            <p className="descrip ">
              Users agree to provide accurate and complete personal information
              when using the Website. Atomos Technologies will handle personal
              information in accordance with its Privacy Policy, available on
              the Website.
            </p>
            <br />
            <br />
            {/* <br /> */}
            <h3 className="mb-3 secondary-heading">5. Payment and Billing</h3>
            <p className="descrip ">
              Payment for services or digital products can be made through the
              Website using the available payment methods. Users agree to
              provide accurate billing and payment information and authorize
              Atomos Technologies to charge the designated payment method for
              the total amount of the transaction.
            </p>
            <br />
            <br />
            <h3 className="mb-3 secondary-heading">6. Intellectual Property</h3>
            <p className="descrip ">
              All content on the Website, including but not limited to text,
              graphics, logos, images, and software, is the property of Atomos
              Technologies and is protected by intellectual property laws. Users
              agree not to reproduce, distribute, display, or create derivative
              works from any content on the Website without the express written
              consent of Atomos Technologies.
            </p>
            <br />
            <br />
            <h3 className="mb-3 secondary-heading">
              7. Limitation of Liability
            </h3>
            <p className="descrip ">
              Atomos Technologies shall not be liable for any indirect,
              incidental, special, consequential, or punitive damages arising
              out of or related to the use of the Website or the services
              provided, even if Atomos Technologies has been advised of the
              possibility of such damages.
            </p>
            <br />
            <br />
            <h3 className="mb-3 secondary-heading">
              8. Governing Law and Jurisdiction
            </h3>
            <p className="descrip ">
              This Agreement shall be governed by and construed in accordance
              with the laws of India. Any dispute arising out of or in
              connection with this Agreement shall be subject to the exclusive
              jurisdiction of the courts in Howrah, West Bengal.
            </p>
            <br />
            <br />
            <h3 className="mb-3 secondary-heading">9. Contact Information</h3>
            <p className="descrip ">
              For any inquiries or concerns regarding these Terms & Conditions,
              please contact us at care@atomostechnologies.com. By using the
              Website, you acknowledge that you have read, understood, and agree
              to abide by these Terms & Conditions.
            </p>
            <br />
            <br />
          </div>
        </div>
      </Slide>
    </>
  );
}

export default Terms;
