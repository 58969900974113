import React from "react";

const Refund = () => {
  return (
    <>
      {}
      <div className="container mt-5">
        <h1 className="main-heading">Refund Policy</h1>
        <div className="content mt-4">
          <h3 className="mb-3 secondary-heading">1. Introduction</h3>
          <p className="descrip ">
            This Refund Policy governs the use of the website
            www.atomostechnologies.com ("Website") and the services and digital
            products offered by Atomos Technologies OPC Private Limited ("Atomos
            Technologies," "we," "us," or "our"). By using the Website and
            engaging in our services, you agree to the terms outlined in this
            Refund Policy.
          </p>
          <br />
          <br />
          <h3 className="mb-3 secondary-heading">
            2. Services and Digital Products
          </h3>

          <p className="descrip ">
            Atomos Technologies specializes in providing software solutions to
            clients. Clients can select services, share personal details, and
            make payments through the Website for the services and digital
            products offered.
          </p>
          <br />
          <br />
          {/* <br /> */}

          {/* <br /> */}
          <h3 className="mb-3 secondary-heading"> 3. No Refunds Policy</h3>
          <p className="descrip ">
            Atomos Technologies operates on a strict no-refund policy for the
            use and purchase of any service, software, or digital product. Once
            a payment is made, it is considered final and non-refundable.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading"> 4. Modification Requests</h3>
          <p className="descrip ">
            Clients have the option to request modifications to their purchased
            services or digital products. Atomos Technologies reserves the right
            to accept or refuse such modification requests at its discretion.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">5. Modification Charges</h3>
          <p className="descrip ">
            If Atomos Technologies chooses to accept a modification request,
            additional charges may be applied. Clients will be required to pay
            these additional charges before the modification process begins.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            6. Refusal of Modification Requests
          </h3>
          <p className="descrip ">
            Atomos Technologies reserves the right to refuse modification
            requests for any reason, including but not limited to technical
            limitations, feasibility concerns, or violation of terms.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">7. Contact Information</h3>
          <p className="descrip ">
            For any inquiries or concerns related to this Refund Policy, please
            contact us at:
            <br />
            -Atomos Technologies OPC Private Limited
            <br />
            -36, Cowies Ghat Road, Shibpur, Howrah, West Bengal, India - 711102
            <br />
            -Email: care@atomostechnologies.com
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">
            8. Changes to Refund Policy
          </h3>
          <p className="descrip ">
            Atomos Technologies reserves the right to update, modify, or change
            this Refund Policy at any time without prior notice. Any changes
            will be effective immediately upon posting on the Website. It is the
            responsibility of the clients to review the Refund Policy
            periodically for updates.
          </p>
          <br />
          <br />
          {/* <br /> */}
          <h3 className="mb-3 secondary-heading">9. Acceptance of Terms</h3>
          <p className="descrip ">
            By using the Website and engaging in our services, you acknowledge
            that you have read, understood, and agreed to the terms outlined in
            this Refund Policy.
          </p>
          <br />
          <br />
          {/* <br /> */}
        </div>
      </div>
    </>
  );
};

export default Refund;
