import React from "react";
import { Link } from "react-router-dom";
import logo from "./../Assets/Images/cardicon.png";

function Footer() {
  return (
    <>
      <div class="container">
        <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 ">
          <div class="col-md-4 d-flex align-items-center">
            <a
              href="/"
              class=" me-2 mb-md-0 text-body-secondary text-decoration-none lh-1"
            >
              <img
                src={logo}
                alt=""
                height="50"
                width="50"
                style={{ borderRadius: "2rem" }}
              />
              {/* <svg class="bi" width="30" height="24">
                <use xlink:href="#bootstrap"></use>
              </svg> */}
            </a>
            <span class=" mb-md-0 text-light text-center">
              © Atomos Technologies (OPC) Private Limited
            </span>
          </div>

          <ul class="nav col-md-4 mt-3 justify-content-end list-unstyled d-flex">
            <li class="ms-3">
              <Link to="/">Home</Link>
            </li>
            <li class="ms-3">
              <Link to="/t&c">T&C </Link>
            </li>
            <li class="ms-3">
              <Link to="/privacy">Privacy</Link>
            </li>
            <li class="ms-3">
              <Link to="/refund">Refund</Link>
            </li>
            <li class="ms-3">
              <Link to="/shipping">Shipping</Link>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
}

export default Footer;
