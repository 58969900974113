import React from "react";
import Amaan from "./../Assets/Images/Amaan.png";
// import Abhi from "./../Assets/Images/Abhi.png";
import Debo from "./../Assets/Images/Debo.png";
import Utkarsh from "./../Assets/Images/Utkarsh.png";
import "./About.css";

function About() {
  // const animation = useAnimation();
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.1,
  // });

  // React.useEffect(() => {
  //   if (inView) {
  //     animation.start({
  //       opacity: 1,
  //       y: 0,
  //       transition: { duration: 1 },
  //     });
  //   }
  // }, [inView, animation]);

  // const [x, setX] = useState(-200);
  // const [yState, setY] = useState(0);
  // console.log(window.pageYOffset);
  // // useEffect(() => {
  // window.addEventListener("scroll", () => {
  //   if (window.scrollY > 9599 || window.scrollY < 12335) {
  //     const y = window.scrollY;
  //     if (yState < y) {
  //       setX(x + 5);
  //       document.documentElement.style.setProperty("--marquee-move", x + "px");
  //     } else if (yState > y) {
  //       setX(x - 5);
  //       document.documentElement.style.setProperty("--marquee-move", x + "px");
  //     }

  //     setY(y);
  //   }
  // });

  return (
    // <motion.div
    //   ref={ref}
    //   initial={{ opacity: 0, y: 50 }}
    //   animate={animation}
    //   className="about-us"
    // >
    <section className="aboutSection" id="about">
      <div
        class="my-who-card reveal-card-zoom snipcss-hP74Q style-2RaLd"
        id="style-2RaLd"
      >
        <div class="marquee">
          <div class="track">
            <div class="content" style={{ color: "#FFAED8" }}>
              &nbsp;Who are we? Who are we? Who are we?{" "}
            </div>
          </div>
        </div>
        <div class="my-who-card__text">
          Atomos Technologies, a top software company, boasts a diverse team of
          tech-savvy experts. Dedicated to providing advanced digital solutions
          for business growth, we've catered to startups, SMEs, and large
          enterprises worldwide since our inception, leveraging our expertise
          across various technologies and industries.
        </div>
        {/* <div className="test" /> */}
        <section class="my-marquee">
          <div class="my-marquee-content scroll-container snipcss0-0-0-1">
            <div class="my-marquee-main snipcss0-1-1-2">
              <div
                class="my-marquee-carousel scroll-element  -v1 swiper-container swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode snipcss0-2-2-3 tether-target-attached-top tether-element-attached-top tether-element-attached-center tether-target-attached-center style-6cOAG"
                id="style-6cOAG"
              >
                <div
                  class="my-marquee-items scroll-element  swiper-wrapper snipcss0-3-3-4 style-J9Q3F"
                  id="swiper-wrapper-cb4e8bd10109810a9ba"
                  aria-live="polite"
                >
                  <div
                    class="my-marquee-item swiper-slide  slide-right swiper-slide-duplicate swiper-slide-duplicate-active snipcss0-4-4-5"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next snipcss0-4-4-6"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-8"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="3 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-20"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-20"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-7"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="6 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-26"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-27"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-33"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-prev snipcss0-4-4-39"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-active snipcss0-4-4-45"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-next snipcss0-4-4-46"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-47"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-48"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="4 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-54"
                    data-swiper-slide-index="4"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-60"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="6 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-66"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-67"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-4-73"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate-prev snipcss0-4-4-79"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active snipcss0-4-4-85"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next snipcss0-4-4-86"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-87"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-88"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="4 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-94"
                    data-swiper-slide-index="4"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-100"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="6 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-106"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-107"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-8-9">
                      <div class="my-marquee-item__text snipcss0-6-9-10">
                        <p class="my-marquee-item__title snipcss0-7-10-11">
                          CEO & Founder
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-10-12">
                          Amaan Ansari
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Amaan}
                        alt="Amaan"
                        class="snipcss0-6-9-13"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-113"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-4-119"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-20-21">
                      <div class="my-marquee-item__text snipcss0-6-21-22">
                        <p class="my-marquee-item__title snipcss0-7-22-23">
                          Sr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-22-24">
                          Debopriya
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Debo}
                        alt="Debopriya"
                        class="snipcss0-6-21-25"
                      />
                    </div>
                  </div>
                </div>
                <span
                  class="swiper-notification snipcss0-3-3-125"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
              <div
                class="my-marquee-carousel -v2 swiper-container swiper-initialized swiper-horizontal swiper-pointer-events swiper-free-mode snipcss0-2-2-126 style-nFgvO"
                id="style-nFgvO"
              >
                <div
                  class="my-marquee-items swiper-wrapper snipcss0-3-126-127 style-FEgiy"
                  id="swiper-wrapper-76cb1796d12b01df"
                  aria-live="polite"
                >
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active snipcss0-4-127-128"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next snipcss0-4-127-129"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-130"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-131"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="4 / 10"
                  />

                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-138"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="6 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-139"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-140"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-141"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-prev snipcss0-4-127-142"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-active snipcss0-4-127-143"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-next snipcss0-4-127-144"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-145"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-146"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="4 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-147"
                    data-swiper-slide-index="4"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-147-148">
                      <div class="my-marquee-item__text snipcss0-6-148-149">
                        <p class="my-marquee-item__title snipcss0-7-134-135">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-134-136">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-133-137"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-153"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="6 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-154"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-155"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide snipcss0-4-127-156"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate-prev snipcss0-4-127-157"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active snipcss0-4-127-158"
                    data-swiper-slide-index="0"
                    role="group"
                    aria-label="1 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next snipcss0-4-127-159"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-160"
                    data-swiper-slide-index="2"
                    role="group"
                    aria-label="3 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-161"
                    data-swiper-slide-index="3"
                    role="group"
                    aria-label="4 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-162"
                    data-swiper-slide-index="4"
                    role="group"
                    aria-label="5 / 10"
                  >
                    <div class="my-marquee-item__image snipcss0-5-162-163">
                      <div class="my-marquee-item__text snipcss0-6-163-164">
                        <p class="my-marquee-item__title snipcss0-7-134-135">
                          Jr. Software Developer
                        </p>
                        <p class="my-marquee-item__name snipcss0-7-134-136">
                          Utkarsh
                        </p>
                      </div>
                      <img
                        draggable="false"
                        src={Utkarsh}
                        alt="Utkarsh"
                        class="snipcss0-6-133-137"
                      />
                    </div>
                  </div>
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-168"
                    data-swiper-slide-index="5"
                    role="group"
                    aria-label="6 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-169"
                    data-swiper-slide-index="6"
                    role="group"
                    aria-label="7 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-170"
                    data-swiper-slide-index="7"
                    role="group"
                    aria-label="8 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-171"
                    data-swiper-slide-index="8"
                    role="group"
                    aria-label="9 / 10"
                  />
                  <div
                    class="my-marquee-item swiper-slide swiper-slide-duplicate snipcss0-4-127-172"
                    data-swiper-slide-index="9"
                    role="group"
                    aria-label="10 / 10"
                  />
                </div>
                <span
                  class="swiper-notification snipcss0-3-126-173"
                  aria-live="assertive"
                  aria-atomic="true"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    // </motion.div>
  );
}

export default About;
